
// .videoWrapper {
//     width: 100%;
//     height: 100%;
//     iframe{
//         width: 100vw;
//         height: 100vh;
//         z-index: 10;
//     }
//   }
//   .ancestorLayout{
//     overflow: hidden;
//   }
//   .invisibleScrollLayer{
//     width: 100%;
//     height: 100%;
//     z-index: 10;
//     position: absolute;
//     background-color: transparent;
//   }
//   .playPuaseBtn{
//     background: transparent;
//     border: none;
//     color: white;
//     position: relative;
//   }
//   .topLayer{
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 10%;
//     background: black;
//     z-index: 20;
// }
// .bottomRight{
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 10%;
//     background: black;
//     z-index: 30; 
//     text-align: end;
// }
// .customControls {
//     position: absolute;
//     bottom: 30px;
//     left: 10px;
//     z-index: 40; 
//     display: -webkit-flex;
//     width: 100%;
//     align-items: center;
//     gap: 20px;
//     padding: 0 20%;
  
//     button {
//         -webkit-appearance: none; 
//         background: transparent;
//         border: none;
//         color: white;
//         opacity: 1; 
//       }
    
//       .slider {
//         -webkit-appearance: none; 
//         width: 100%;
//         height: 5px;
//         border-radius: 5px;
//         background: #ddd;
//         outline: none;
//         opacity: 1;
//         transition: opacity .2s;
    
//         &::-webkit-slider-thumb {
//           -webkit-appearance: none; 
//           width: 15px;
//           height: 15px;
//           border-radius: 50%;
//           background: white;
//           cursor: pointer;
//         }
    
//         &::-moz-range-thumb {
//           width: 15px;
//           height: 15px;
//           border-radius: 50%;
//           background: #4CAF50;
//           cursor: pointer;
//         }
//       }
//     }
    
//     @media (max-width: 768px) {
//       .customControls {
//         bottom: 20%;
//       }
//     }


//WO YOUTUBE
.ancestorLayout{
    // height: fit-content !important;
    min-height: fit-content !important;
}
.parentLayout{
    width: unset !important;
}
