.mapContainer {
  width: 100%;
}
.mapContainer{

}
.exGroup{
  display: flex;
  flex-direction: row;
  gap: 30%;
  width: 100%;
}
.mapContainer{
  // width: 50%;
}
.expandable{
width: 100%;
}
.map{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pageTitle{
  text-align: center;
  margin-bottom: 60px;
  color: black;
}
.ancestorLayout {
  max-height: unset !important;
  min-height: unset !important;
  height: fit-content !important;
  padding: 100px 0;
  background-color: #F8EFDD;
  overflow-x: hidden;
  align-items: center !important;
  justify-content: center !important;
}

.parentLayout {
  flex-direction: column-reverse !important;
  gap: 100px;
}
.ExTitleAndIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.ExTitle {
  font-size: 20px;
  font-weight: 600;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-right: 20px;
  gap: 10px;

  &::after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background-color: black;
    margin-left: 10px;
    padding-left: 20px;
  }
}
.ExRootContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ExIcon {
  font-size: 16px;
}
@media (max-width: 768px) {
  .parentLayout {
    flex-direction: column-reverse !important;
  }
  
}
@media (max-width: 900px) {
  .exGroup {
    width: 100%;
    flex-direction: column !important;
    gap: 20px;
  }
  .expandable,
  .mapContainer {
    width: 100%;
  }
} 
// Add this to your Section8.module.scss

.mapContainer {
  position: relative;
}
.magnifierLens{
  background-color: #F8EFDD;
  width: 400px !important;
  height: 400px !important;
  border-radius: 0 !important;

}
@media (max-width: 900px) {
  .magnifierLens{
    width: 360px !important;
    height: 360px !important;
    left: -10% !important;
    border-radius: 0 !important;
  }

  
}

.ExNameTime{
 display: flex;
 flex-direction: row;
 justify-content: space-between;  
 gap: 20px;; 
}
.ExBodyName{

}
.ExTime{
  width: 26%;
  text-align: right;
}