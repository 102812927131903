.ancestorLayout{
    // padding-top: 300px;
}
.BgImgContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.parentLayout{
    align-items: flex-start !important;
    // z-index: 200px;
    gap: 90px;
}

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  
.heroText{
    z-index: 2;
    color: white;
    font-size: 100px;
    animation-name: fadeInUp;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

.futureText{
    color: #FAFF00;
}
.BgImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.socialIcons{
    position: absolute;
    z-index: 2;
    font-size: 20px;
    color: black;
    display: flex;
    right: 0px;
    flex-direction: column;
    gap: 20px;
}
.subHero{
    color: white;
    font-size: 30px !important;
}
.socialIcons{
    a{
        color: white;
        transition: all 0.4s ease-in-out;

        &:hover{
            color: #FAFF00;
            transition: all 0.4s ease-in-out;
            cursor: pointer;
        }
    }
}
@media (max-resolution: 144dpi) {
    .heroText {
        font-size: 60px; 
    }
    .subHero{
        font-size: 20px !important;
    }
    .parentLayout{
        gap: 50px;
    }
}
@media (min-resolution: 144dpi) and (max-width: 1240px) {
    .heroText {
        font-size: 60px;
    }
    .subHero{
        font-size: 20px !important;
    }
    .parentLayout{
        gap: 50px;
    }
  }
  

@media (max-width: 768px) {
    .ancestorLayout{
        height: 100vh !important;
    }
    .heroText{
        font-size: 60px;
        margin-top: 200px;
    }
    .subHero{
        font-size: 20px !important;
    
    }
}
@media (max-width: 600px) {
    .heroText{
        font-size: 50px;
        margin-top: 200px;
    }
}
@keyframes zoomInOut {
    0%, 100% {
      transform: scale(1); /* Start and end at normal size */
    }
    50% {
      transform: scale(1.1); /* Zoom in to 110% at the halfway point */
    }
  }
  .BgImg{
    animation: zoomInOut 30s infinite; /* Apply the animation, lasting 5 seconds, and loop it infinitely */

  }