.Home{
    padding: 0;
}

/* Header.module.scss or Header.module.css */
.snapParent{
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow: auto;
}

.header {
    position: fixed;
    top: -100px; /* Initially set the header outside the viewport */
    width: 100%;
    height: 100px; /* Adjust based on your header's height */
    transition: top 0.5s ease-out; /* Smooth transition effect */
  }
  
  .headerVisible {
    top: 0; /* Bring the header inside the viewport */
  }
  .pageNumber{
    position: fixed;
    top: 50%;
    right: 20px;
    z-index: 9998;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    
    span{
      position: relative;
      color: gray;
      &::before{
        content: "";
        position: absolute;
        width: 20px;
        background: gray;
        height: 1px;
        top: 50%;
        left: -28px;
      }
    }
  }
  .active {
    color: black !important;
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
      &::before{
        background-color: black !important;
      }
}

@media (max-height: 700px) {
  .snapParent{
    scroll-snap-type: unset;
  }
}