.ancestorLayout {
    height: 100vh; 
    background-color: #819673;
    height: 100vh !important;
    padding: 80px 0;
    min-height: 800px !important;
  }
  
  .parentLayout {
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .iconText{
    text-transform: uppercase;
  }
  .iconsDiv {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 8 frames as per your requirement */
    gap: 80px;
    column-gap: 10%;
    width: 100%;
}
.iconTextDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 100%;
  height: 100%;
  width: 100%;
}
.carousel{
    width: 100%;
}

.icon {
  height: 80px;
  width: 80px;
}
.pageTitle{
  text-align: center;
  margin-bottom: 60px;
  color: white;
}
.iconTextDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.iconText{
  color: white;
  text-align: center;
}

@media (max-width: 1300px) {
  .iconsDiv {
      row-gap: 10px;
      column-gap: 60px;
      grid-template-columns: repeat(2, 1fr); 

  }

  .iconTextDiv {


}
}

@media (max-width: 768px) {

  .ancestorLayout{
    padding-top: 100px !important;
  }

.Carousel{
  width: 100%;
  overflow: hidden;
}

.icon{
  height: 40px;
  width: 40px;
}
.iconTextDiv {
 
}
}
@media (max-width: 500px) {
  .parentLayout{
    position: unset !important;
  }
}