 * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.snap-child{
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.ancestorLayout{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100vh;
min-height: 700px;
max-height: 1400px;
position: relative;
scrollbar-width: none; /* For Firefox */
-ms-overflow-style: none;  /* For Internet Explorer and Edge */
&::-webkit-scrollbar {
  display: none;
}
}
.parentLayout{
  position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 80%;
scrollbar-width: none; /* For Firefox */
-ms-overflow-style: none;  /* For Internet Explorer and Edge */
&::-webkit-scrollbar {
  display: none;
}
}
.button{
  padding: 10px 20px;
}
.heroText{
  font-family: 'Playfair Display', serif;
}
.ralewayFont{
  font-family: 'Raleway', sans-serif;
}
.playfairFont{
  font-family: 'Playfair Display', serif;
}
.archiaFont{
  font-family: 'Archia', sans-serif;
}
.body{
  font-family: 'Archia', sans-serif ;
}
.poppins {
  font-family: 'Poppins', sans-serif;
}

p{
  font-family: 'Archia', sans-serif ;
  font-size: 16px !important;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Raleway', serif;
  font-weight: 600 !important;
  font-size: 32px !important;
}
.textBody{
  font-family: 'Archia', sans-serif ;
}
.textTitle{
  font-family: 'Raleway', serif;
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes floatAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}


// .section4Img, .section3Img{
//   animation: floatAnimationImg 5s ease-in-out infinite;
// }
@keyframes floatAnimationImg {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.footer{
  height: fit-content !important;
}
.fp-watermark{
  display: none !important;
}
/* Header.module.scss or Header.module.css */

@media (max-width: 768px) {
  .video{
    height: fit-content !important;
  }
  
}
