.ancestorLayout {
  position: fixed !important;
  height: fit-content !important;
  max-height: unset !important;
  min-height: unset !important;
  background-color: #f8efddd1;
  transition: all 0.6s ease-in-out;
  z-index: 10000;
  top: 0;
  // opacity: 0;
  // &:hover {
  //   opacity: 1;
  //   transition: all 0.6s ease-in-out;
  // }
  
}
.alwaysVisible {
  opacity: 1 !important; // Override the opacity to make it always visible
}

.parentLayout {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;
  padding: 10px;
  position: relative;
}
.burgerIcon {
  color: black;
}
.iconContainer {
  cursor: pointer;
}
.logoContainer {
}
.SaikamLogo {
  width: 100px;
}



.pageNames {
  display: flex;
  gap: 40px;
  font-weight: 600;
  font-size: 14px;
}

.buttonContainer {
  padding: 10px;
}
.brochureBtn {
  background-color: black !important;
  color: white !important;
  border: none;
  border-radius: 0 !important;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-size: 14px !important;
  &:hover {
    background-color: #00000073 !important;
    transition: all 0.2s ease-in-out;
  }
}

.drawer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.closeIcon {
  position: absolute;
  right: 40px;
  top: 40px;
}
.drawerContent {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  padding: 20px;
}
.iconContainer {
  display: none;
}

@media (max-width: 1300px) {
  .pageNames {
    gap: 20px;
  }
}
@media (max-width: 1150px) {
  .pageNames,
  .buttonContainer {
    display: none;
  }
  .iconContainer {
    display: block;
  }
}
.page{
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover{
    color: #242a207d;
    transition: all 0.2s ease-in-out;
  }
}
@media (max-width: 900px) {
  .SaikamLogo{
    width: 50px;

  }
  .ancestorLayout{
  padding: 0;
  opacity: 1;
  }
  
  
}

@media (max-width:768px) {
  .ancestorLayout{
    // background-color: #f8efdd4a;
  }
}