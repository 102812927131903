.App{
    background-color: #F8EFDD;
    // height: 100vh;
    position: relative;
    // padding-top: 150px !important;
    
}
.cus-side-btns{
    position: fixed;
    right: -156px;
    top: 50%;
    transform: rotate(-90deg);
    z-index: 999;
    display: flex;
    gap: 30px;
}
.cus-brochure-btn{
    padding: 6px 12px;
    background-color: rgba(0, 0, 0, 0.466);
    color: white;
    border: none;
}
.cus-enquire-btn{
    padding: 6px 30px;
    background-color: #faff00;
    color: #000000ab;
    border: none;
}


