.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
  }
  
  .popupContent {
    // background: #fff;
    border-radius: 8px;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  
  .closeBtn {
    position: absolute;
    top: 40px;
    right: 50px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
    z-index: 999;
  }
  




  ///Form
  /// 
  /// 
  /// 
  .parentLayout{
    gap: 50px;
    // padding: 0px 200px;
    color: white;
    position: unset !important;
}
.ancestorLayout{
    background-color: #242A20;
    height: fit-content !important;
    width: 80%;    
    min-height: 600px;
    
}
.checkBoxErrorMessage{
  position: absolute;
  left: 0;
  top: 40px;
  color: red;
}
.form{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
  .input{
    flex: 1;
    background-color: unset !important;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    padding-bottom: 8px;
    font-size: 16px;
    border-radius: unset;
    &:focus{
      outline: none;
    }
  }
.inpLabel{
    width: 150px;
    text-align: left;


}
.inpLabel, .checkBoxMainLabel{
    font-weight: 600 !important;
}
  .inputGroup{
    display: flex;
    width: 100%;
    position: relative;
  }
  .checkBoxSection{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    flex: 1;
    justify-content: space-evenly;
  }

  .checkBoxGroup{
    display: flex;
    flex-direction: row;
    gap: 10px;
    // flex: 1;
  }
  .checkBoxMainLabel{
    flex-grow: 0 0 auto;
  }
  .pageTitle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .submitBtn{
    background-color: unset !important ;
    color: white;
    box-shadow: unset;
    border: 1px solid white;
    margin-top: 20px;
    transition: all 0.4s ease-in-out;
    width: fit-content;
    align-self: center;

    &:hover{
      transition: all 0.4s ease-in-out;
      border: 1px solid yellow;
      color: yellow;
    }
  }
  .popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 200;
    color: white;
    span {
        padding: 20px;
        border-radius: 10px;
    }

    .popupContent {
        position: relative;
        background-color: #242A20;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        width: auto;  /* Adjust as necessary */
        max-width: 90%; /* Prevents the popup content from being too wide on large screens */
    }
    
 
    .closeBtn{
      background-color: unset !important;
      color: white;
      border: 1px solid white;
    }
}
.checkboxInput {
  display: none; // Hide the checkbox
}
.checkboxLabel{
  display: flex;
  align-items: center;
  gap: 10px;
}

  @media (max-width: 768px) {
    .form{
      gap: 20px !important;
    }
    .checkBoxErrorMessage{
      top: 80px;
    }
    .inputGroup{
        flex-direction: column;
    }
    .checkBoxSection{
        justify-content: space-between;
    }
    .inputGroup:nth-child(4){
        gap: 20px;
    }
    .popupContent{
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
  .aweBox{
    transition: all 0.4s ease-in-out;
    &:hover{
      cursor: pointer;
      color: yellow;
      transition: all 0.4s ease-in-out;
    }
  }
  @media (max-height: 650px) {
    .popupContent{
      align-items: flex-start;
    }
  }
  @media (max-width: 480px) {
    .popupContent{
      align-items: center;
    }
  
    .ancestorLayout{
      width: 90%;
    }
    
    .closeBtn{
      top: 20px;
      right: 20px;
    }
    
  }