.ancestorLayout {
    height: fit-content !important;
    min-height: unset !important;
    max-height: unset !important;
    background-color: #D9C7B3;
    flex-direction: column !important;
}

.parentLayout {
    flex-direction: column !important;
    gap: 80px;
    position: unset !important;
    height: 100vh !important;
}
.dynamicContent{
    display: flex;
    flex-direction: column;
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow-y: scroll;
    flex: 1;
}
// .pageNumber{
//     position: absolute;
//     right: 20px;
//     top: 30%;

// }
.contentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex: 0 0 100%;
    height: 100%;
    text-align: center;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    box-sizing: border-box;
    gap: 40px;
    &:nth-child(odd){
        flex-direction: row;
    }
    &:nth-child(even){
        flex-direction: row-reverse;
}
}
.pageNumber {
display: flex;
flex-direction: column;
gap: 20px;
transition: all 0.4s ease-in-out;
  }
  
.activePage {
  color: black; // Change the color for the active page
  transform: scale(1.2); // Scale up a little bit
  transition: all 0.4s ease-in-out;

  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: -3.38em;  // Adjust this value to position the line as you like
    top: 50%;
    transform: translateY(-50%);
    width: 40px;  // Width of the line
    height: 1px;  // Thickness of the line
    background-color: black;  // Color of the line
  }
}
.dynamicContent::-webkit-scrollbar {
    display: none;  // Hide scrollbar for Webkit browsers
}

.dynamicContent {
    -ms-overflow-style: none;  // Hide scrollbar for IE and Edge
    scrollbar-width: none;  // Hide scrollbar for Firefox
}

.page {
    color: rgba(0, 0, 0, 0.509);
    position: relative;
    font-size: 16px !important;
  
    &::before {
      content: "";
      position: absolute;
      left: -3em;  // Adjust this value to position the line as you like
      top: 50%;
      transform: translateY(-50%);
      width: 30px;  // Width of the line
      height: 1px;  // Thickness of the line
      background-color: rgba(0, 0, 0, 0.509);  // Color of the line
    }
  }
.imageContainer {
    width: 50%;
    height: 100%;
}
.image {
    width: 100%;
    height: 100%;
    object-fit: contain;}
.textContentContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    width: 50%;
}
.pageName {}
.textTitle {}
.bodyText, .textTitle, .pageName {
    text-align: right;
}
.textTitle{
    font-family: 'Poppins', sans-serif !important;
    font-size: 26px !important;

}


.pageName{
font-size: 19px !important;
}
@media (max-width:1000px) {
    .contentContainer{
        flex-direction: column !important;
        gap: 40px;
        width: 100%;
    }
    .textContentContainer{
        width: 100%;
        align-items: center;
    }
    .imageContainer{
        width: 300px;
        height: unset;
    }
    .bodyText, .textTitle, .pageName {
        text-align: center;
    }

    .pageNumber{
        position: absolute;
        right: 20px;
        top: 30%;
    }
    .activePage{
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: -3.6em;  // Adjust this value to position the line as you like
            top: 50%;
            transform: translateY(-50%);
            width: 30px;  // Width of the line
            height: 1px;  // Thickness of the line
            background-color: black;  // Color of the line
          }
    }
    .page{
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: -3.6em;  // Adjust this value to position the line as you like
            top: 50%;
            transform: translateY(-50%);
            width: 30px;  // Width of the line
            height: 1px;  // Thickness of the line
            background-color: rgba(0, 0, 0, 0.296);  // Color of the line
          }
    }
    
}
@media (max-width:768px) {
    .imageContainer{
        width: 200px;
    }
    .textContentContainer{
        gap: 0px;
    }
    .bodyText .pageName {
        font-size: 16px;
        }
        .textTitle{
            font-size: 16px !important;
        }
        .page, .activePage{
            font-size: 10px !important;
        }
}