.ancestorLayout {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 100vh;
  // background-color: #f7f7f7;
  // padding-top: 80px;
  overflow: auto;
  flex-direction: column !important;
  justify-content: flex-start !important;
}

.parentLayout {
  // width: 80%;
  // background-color: #ffffff;
  // padding: 20px;
  // border-radius: 8px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // overflow-y: auto;
  // max-height: 90vh;
  justify-content: flex-start !important;
  text-align: left;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;

  }
  h2{
    font-size: 18px !important;
    width: 100%;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    width: 100%;
    
  }
}
