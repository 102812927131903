.textTitle{
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}
.textBody{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.textTitle, .textBody{
    color: white;
}
.ancestorLayout{

    padding: 100px 0px;
    background-color: #242A20;
    // animation: changeBackgroundColor 30s infinite; 

}

@keyframes changeBackgroundColor {
    0% {
      background-color: #242A20;
    }
    25% {
      background-color: #123456; /* Some other color */
    }
    50% {
      background-color: #789ABC; /* Another color */
    }
    75% {
      background-color: #334456; /* Yet another color */
    }
    100% {
      background-color: #242A20; /* Return to the original color */
    }
  }
  