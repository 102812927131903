// .ancestorLayout{
//     height: fit-content !important;
//     min-height: unset !important;
//     max-height: unset !important;
//     padding: 100px 0px;
//     background-color: black;
//     color: white;
// }
// .pagesContainer {
//     display: grid;
//     grid-template-columns: repeat(4, 1fr);
//     grid-template-rows: repeat(4, auto);
//     justify-items: center;
//     align-items: center;
//     gap: 16px;
//     text-transform: uppercase;
//     text-align: center;
//     font-size: 15px;
//   }
//   .privacyPolicy,
// .termsOfServices {
//   grid-column: span 2;
// }
// .home, .plans, .location, .contactUs{
//     color: yellow;
// }

// .contactNo,
// .socialIconContainer {
//   grid-column: 1 / -1; /* Span the full width */
// }
// .socialIconContainer{
//     display: flex;
//     gap: 30px;
//     justify-content: center;
//     a{
//         color: white;
//         &:hover{
//             color: rgba(255, 255, 255, 0.424);
//         }
//     }
// }

// @media (max-width: 768px ) {
//     .pagesContainer {
//         font-size: 14px;
//         grid-template-columns: repeat(2, 1fr);
//         grid-template-rows: repeat(8, auto);
//     }
//     .privacyPolicy,
//     .termsOfServices {
//         grid-column: span 1;
//     }
//     .contactNo,
//     .socialIconContainer {
//         grid-column: 1 / -1; /* Span the full width */
//     }
// }
// .poweredBy{
//     text-decoration: none;
//     color: white;
//     text-transform: uppercase;
//     &:hover{
//         color: rgba(255, 255, 255, 0.514);
//     }
// }
// .home, .plans, .location, .contactUs, .privacyPolicy, .termsOfServices{
//     transition: all 0.2s ease-in-out;

//     &:hover{
//         color: rgba(255, 255, 255, 0.425);
//         cursor: pointer;
//         transition: all 0.2s ease-in-out;
//     }
// }
// .bottom{
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     bottom: 0;
//     gap: 10px;

// }
.gridContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.power, .saikam{
    text-align: center;
    color: rgba(255, 255, 255, 0.438);
}
.saikam{
    //    margin-top: 30px; 
    text-decoration: none;
}
.title,.bodyText{
    font-size: 14px !important;
    color: white;
   

}
.bodyText{
    margin-bottom: 0 !important;
}
.title{
    font-weight: 400 !important;
    margin-bottom: 20px !important;
}
.label{
    color: rgba(255, 255, 255, 0.431);
    text-transform: uppercase;
    font-size: 14px !important;
}
.ancestorLayout{
    background-color: black;
    height: fit-content !important;
    min-height: unset !important;
    padding: 100px 0px 50px 0;
}
.imgContainer, .infoGroup, .contactUsGroup, .findUsGroup{
    flex: 1;
}
.formText{
    &:hover{
        cursor: pointer;
    }
}
.socials{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    color: white;

}
.fb, .ig{
    color: rgba(255, 255, 255, 0.597);
}
.end{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

}
@media (max-width: 900px) {
    .gridContainer{
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;
    // text-align: center;

    }
}
.bottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: rgba(255, 255, 255, 0.438);
}
.parentLayout{
    display: flex;
    flex-direction: column;
    gap: 80px;
}
.bottomRight{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.power{
    text-transform: uppercase;
    text-decoration: none;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.438);
}
.privacy, .terms, .cookie{
text-decoration: none;
color: rgba(255, 255, 255, 0.438);
text-align: center;
}

@media (max-width:768px) {
    .power, .credvest{
        text-align: center !important;
    }
    .bottom{
        flex-direction: column;
        gap: 20px;
    }
}
@media (max-width: 600px) {
    .footerSaikamLogo{
        width: 70%;
        display: flex;
    }
    .gridContainer{
        gap: 40px;
    }
}