.gridBody {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 30px;
  width: 100%;
}
.infoBlock{
  text-align: start;
}

.infoBlock:nth-child(2) {
  text-align: center;
}
.infoBlock:nth-child(5) {
    text-align: center;
  }
  .infoBlock:nth-child(8) {
    text-align: center;
  }

  .infoBlock:nth-child(3) {
    text-align: end;
  }
  .infoBlock:nth-child(6) {
      text-align: end;
    }
    .infoBlock:nth-child(9) {
      text-align: end;
    }
.pageTitle{
    margin-bottom: 40px;
}
.subTitle{
    font-size: 28px !important;
    font-weight: 600 !important;
}
.ancestorLayout{
    background-color: #242A20;
    color: white;
    max-height: unset !important;
    height: 100vh !important;
    padding: 100px 0;
}
@media (max-width: 1000px) {
    .gridBody{
        grid-template-columns: repeat(2, 1fr);
    }
    .infoBlock{
        &:nth-child(odd) {
            text-align: left;
        }
        &:nth-child(even) {
            text-align: right;
        }
        
      }
}
@media (max-width: 768px) {
    .gridBody{
        grid-template-columns: repeat(1, 1fr);
    }   
    .infoBlock{
        text-align: center !important;
    }
    .subTitle{
        font-size: 20px !important;
        font-weight: 600 !important;
    }
    .ancestorLayout{
        height: fit-content !important;

    }
    
}