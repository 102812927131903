.parentLayout{
    flex-direction: row !important;
    gap: 50px !important;
}
.ancestorLayout{
position: relative;
overflow: hidden;
// min-height: fit-content !important;
}
.heading{
  font-size: 32px !important;
  font-weight: 600 !important;
}
.textPane{
    color: #FFFFFF;
  }
  .bgBlock{
    content: '';
    position: absolute;
    background-color: #819673;
    height: 100%;
    width: 60%;
    left: 0;
  }
  .imagePanel, .textPane{
    width: 50%;
  }
  .imagePanel{
    height: 100%;
    padding: 30px;
  }
  .mapImage{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    .textPane{
      text-align: center;
    }
    .heading{
        font-size: 22px !important;
    }
    .description{
        font-size: 14px !important;
    }
    .imagePanel{
        height: 300px !important;
    }
    .bgBlock{
        width: 100%;
        height: 60%;
        bottom: 0;
        margin: unset;
    }
    .parentLayout{
        flex-direction: column-reverse !important;
    }
    .ancestorLayout{
      height: fit-content !important;
      overflow: auto;
    }
    .imagePanel, .textPane{
        width: 100%;
      }
      .imagePanel{
        padding: 0;
        height: 380px;
      }
  }
  