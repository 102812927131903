.thankyouRoot{
    left: 20%;
    right: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #242a20;
    height: 100vh;
    width: 100%;
    color: white;
    .alignment {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }
    
    .backBtn{
        padding: 10px 20px;
        color: white;
        background-color: #242a20;
        border: 1px solid white;
        align-self: center;
        text-align: center;
        margin-top: 20px;
        
    
    }
    h3{
        text-align: center;
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 30px;


    }
}
@media (max-width: 568px) {
    .alignment{
        width: 90% !important;
        h3{
            font-size: 16px !important;
        }
    }
}